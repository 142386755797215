import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { BasicButton } from '@/components/uis/Button/BasicButton'

const ModalFooter = styled(Box)`
  display: flex;
  gap: 16px;
  padding: 16px 0 0 0;
  justify-content: center;
`

const ModalText = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.448;
`

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export const LlmModelChangeModal = ({ open, onClose, onSubmit }: Props) => {
  return (
    <BasicModal open={open} onClose={onClose} title='モデルを変更します。'>
      <>
        <ModalText>
          選択されたモデルは異なる会社のものであるため、現在のチャットセッションを引き継ぐことができません。
          <br />
          新規チャットを作成します。
        </ModalText>
        <ModalFooter>
          <BasicButton
            label='キャンセル'
            variant='outlined'
            width='140px'
            onClick={onClose}
          />
          <BasicButton label='実行' width='140px' onClick={onSubmit} />
        </ModalFooter>
      </>
    </BasicModal>
  )
}

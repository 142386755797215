import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query'

import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'

import { Histories } from './useFetchHistories'

type DeleteHistoryResponse = {
  data: string
}

type UseDeleteHistoryArgument = {
  token: string
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<void | Histories, Error>>
}

type MutationVariables = {
  chatRoomId: string
}

export const useDeleteHistory = ({ token, refetch }: UseDeleteHistoryArgument) => {
  const { apiClient } = useApiClient()
  const client = apiClient(token)

  return useMutation<DeleteHistoryResponse, Error, MutationVariables>({
    mutationKey: MutationKeys.deleteHistory,
    mutationFn: async ({ chatRoomId }) => {
      return await client
        .patch<DeleteHistoryResponse>(`${API.history}/${chatRoomId}`)
        .then((res) => res.data)
    },
    onSuccess: async () => {
      // invalidateQueriesで書いた方が引数で受け取る必要ないためコードがシンプルになる
      // 履歴を２回以上開くと再フェッチを行わないバグ？がある
      // 回避策として引数でrefetchを受け取ってここで実行している
      // await queryClient.invalidateQueries({ queryKey: MutationKeys.history})
      await refetch()
    },
  })
}

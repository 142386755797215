import { useCallback, useEffect, useRef, useState } from 'react'

import { HandleSendChatType } from '../components/page/Chat'

type Props = {
  handleSendChat: HandleSendChatType
  handleChangeChatInput: (value: string) => void
  chatInputValue: string
}

export const useSpeechRecognition = ({
  handleSendChat,
  handleChangeChatInput,
  chatInputValue,
}: Props) => {
  const speechRecognitionRef = useRef<SpeechRecognition>()
  const [isRecording, setIsRecording] = useState(false)

  /**
   * 音声認識が終わった時に動く処理
   */
  const handleRecognitionResult = useCallback(
    (event: SpeechRecognitionEvent) => {
      // 認識されたテキストを取得しStateへ保存
      console.log('HEY')
      const results = event.results
      let result = ''
      for (let i = event.resultIndex; i < results.length; i++) {
        result += results[i][0].transcript
      }
      handleChangeChatInput(result)

      if (!results[0].isFinal || !chatInputValue) return
      setIsRecording(false)

      // チャット送信
      handleSendChat({
        callback: () => {
          // 即座に有効化するとstate更新の関係で意図通り動作しないので適当な秒数空けて録音を再開する
          setTimeout(function () {
            speechRecognitionRef.current?.start()
            setIsRecording(true)
          }, 500)
        },
      })
    },
    [handleSendChat, chatInputValue, handleChangeChatInput],
  )

  /**
   * 音声認識がストップした時に動く処理
   */
  const handleRecognitionEnd = useCallback(() => {
    setIsRecording(false)
  }, [])

  useEffect(() => {
    if (!speechRecognitionRef.current) {
      // クロスブラウザ対応のため、SpeechRecognition オブジェクトを取得
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

      // Speech Recognition API のインスタンスを生成
      const recognition = new SpeechRecognition()
      // 認識中の暫定結果をonresultで取得できるようにする
      recognition.interimResults = true
      speechRecognitionRef.current = recognition
    }

    speechRecognitionRef.current.onresult = handleRecognitionResult
    speechRecognitionRef.current.onend = handleRecognitionEnd

    return () => {
      speechRecognitionRef.current?.removeEventListener('result', handleRecognitionResult)
      speechRecognitionRef.current?.removeEventListener('end', handleRecognitionEnd)
    }
  }, [handleRecognitionResult, handleRecognitionEnd])

  // 録音を処理する関数
  const startRecording = useCallback(() => {
    // 録音を開始します。
    speechRecognitionRef.current?.start()

    setIsRecording(true)
  }, [speechRecognitionRef])

  const stopRecording = () => {
    speechRecognitionRef.current?.stop()
  }

  // コンポーネントがアンマウントされたら録音を止める
  useEffect(() => {
    return () => {
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.abort()
      }
    }
  }, [])

  return {
    isRecording,
    startRecording,
    stopRecording,
  }
}

import React from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import styled from '@emotion/styled'

import { SendIcon } from '@/components/icon/SendIcon'
import { MicrophoneIcon } from '@/components/icon/MicrophoneIcon'
import { DeleteIcon } from '@/components/icon/DeleteIcon'
import {
  accentColor,
  functionalColor,
  outlineColor,
} from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'

import { LlmModelSetting } from './LlmModelSetting'
import { LlmModel, LlmModels } from '../../../types'
import {
  ChangeLlmModelType,
  HandleModalSubmitArgumentType,
} from '../../../hooks/useLlmModel'
import { HandleSendChatType } from '../../page/Chat'

const Container = styled(Box)`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 40px auto;
  gap: 12px;
  padding-top: 8px;
  ${mediaQuery('tab')} {
    position: fixed;
    bottom: 64px;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #fff;
  }
`

const ChatInputContainer = styled(Box)`
  display: flex;
  border: 1px;
  border-style: solid;
  border-radius: 6px;
  border-color: ${outlineColor.lightGray};
  padding: 8px;
  align-items: center;
  grid-row: 2;
  grid-column: 1;
  ${mediaQuery('tab')} {
    grid-column: 1 / 3;
  }
`

const ChatInput = styled(TextField)`
  width: 100%;
  padding: 0;
  & .MuiInputBase-root {
    padding: 0;
  }
  & .MuiInputBase-input {
    font-weight: 400;
    line-height: 1.448;
  }
  & .MuiFormHelperText-root {
    color: ${functionalColor.red};
  }
`

const SendButton = styled(IconButton)`
  padding: 0;
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
`

const MicrophoneButtonContainer = styled(Box)`
  grid-row: 2;
  grid-column: 2;
  display: flex;
  place-items: center;
  ${mediaQuery('tab')} {
    grid-row: 1;
    grid-column: 2;
  }
`

const MicrophoneButton = styled(IconButton)`
  border-radius: 22px;
  padding: 2px;
  background-color: ${accentColor.lightRed};
  width: 40px;
  height: 40px;
`

type Props = {
  handleSendChat: HandleSendChatType
  chatInputValue: string
  handleChangeChatInput: (text: string) => void
  isValid: boolean
  changeLlmModel: ChangeLlmModelType
  selectedLlmModel: LlmModel | undefined
  llmModels: LlmModels
  isAnswering: boolean
  isOpen: boolean
  isMobile: boolean
  handleCloseModal: () => void
  handleModalSubmit: HandleModalSubmitArgumentType
  isRecording: boolean
  startRecording: () => void
  stopRecording: () => void
}

export const ChatForm = ({
  handleSendChat,
  chatInputValue,
  handleChangeChatInput,
  isValid,
  changeLlmModel,
  selectedLlmModel,
  llmModels,
  isAnswering,
  isOpen,
  isMobile,
  handleCloseModal,
  handleModalSubmit,
  isRecording,
  startRecording,
  stopRecording,
}: Props) => {
  const canSendChat = !chatInputValue.trim() || isAnswering || !isValid

  const handleRecordButtonClick = () => {
    if (isRecording) {
      stopRecording()
    } else {
      startRecording()
    }
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeChatInput(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === 'Enter' &&
      event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey &&
      !event.nativeEvent.isComposing
    ) {
      event.preventDefault()
      if (chatInputValue.trim()) {
        handleSendChat()
      }
    }
  }

  const sendChat = () => {
    handleSendChat()
  }

  return (
    <Container>
      <LlmModelSetting
        changeLlmModel={changeLlmModel}
        selectedLlmModel={selectedLlmModel}
        llmModels={llmModels}
        isModalOpen={isOpen}
        handleCloseModal={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
      />
      <ChatInputContainer>
        <ChatInput
          placeholder='気になることを質問してみましょう'
          variant='standard'
          InputProps={{ disableUnderline: true }}
          value={chatInputValue}
          onChange={handleChangeInput}
          inputProps={{
            autoComplete: 'off',
          }}
          multiline
          maxRows={isMobile ? 7 : 10}
          onKeyDown={handleKeyDown}
          disabled={isRecording}
          helperText={!isValid ? '3000文字以内で入力してください' : undefined}
        />
        <SendButton onClick={sendChat} disabled={canSendChat}>
          <SendIcon />
        </SendButton>
      </ChatInputContainer>
      <MicrophoneButtonContainer>
        <MicrophoneButton onClick={handleRecordButtonClick} disabled={isAnswering}>
          {isRecording ? <DeleteIcon /> : <MicrophoneIcon />}
        </MicrophoneButton>
      </MicrophoneButtonContainer>
    </Container>
  )
}

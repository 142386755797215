import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'

import { mediaQuery } from '@/utils/helpers/breakpoint'
import { textColor } from '@/utils/themeConfigs/customTheme'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { EditIcon } from '@/components/icon/EditIcon'

const Container = styled(Button)`
  ${mediaQuery('tab')} {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
`

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.448;
  color: ${textColor.red};
`

type Props = {
  onClick: () => void
}

export const NewChatButton = ({ onClick }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet()

  return (
    <Container startIcon={<EditIcon />} onClick={onClick}>
      {!isMobileOrTablet && <Text>新しいチャットを始める</Text>}
    </Container>
  )
}

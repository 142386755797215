import Link from 'next/link'
import { IconButton, styled, Typography } from '@mui/material'

import { DeleteIcon } from '@/components/icon/DeleteIcon'
import { bgColor, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'

const Item = styled(Link, {
  shouldForwardProp: (props) => props !== 'isCurrent',
})<{ isCurrent: boolean }>`
  width: 100%;
  height: 50px;
  padding: 12px 16px 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isCurrent }) =>
    isCurrent ? bgColor.halfTransparentWhite : 'inherit'};
`

const ItemText = styled(Typography, {
  shouldForwardProp: (props) => props !== 'isCurrent',
})<{ isCurrent: boolean }>`
  font-weight: ${({ isCurrent }) => (isCurrent ? fontWeight.bold : fontWeight.normal)};
  font-size: 0.875rem;
  line-height: 1.448;
  color: ${textColor.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

type Props = {
  id: string
  text: string
  href: string
  isCurrent: boolean
  onGoToChatRoom?: () => void
  modalOpen: (id: string) => void
}

export const HistoryItem = ({
  id,
  text,
  href,
  isCurrent,
  onGoToChatRoom,
  modalOpen,
}: Props) => {
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const id = event.currentTarget.dataset.id ?? ''
    modalOpen(id)
  }

  return (
    <Item href={href} onClick={onGoToChatRoom} isCurrent={isCurrent}>
      <ItemText isCurrent={isCurrent}>{text}</ItemText>
      <IconButton onClick={handleDelete} data-id={id}>
        <DeleteIcon />
      </IconButton>
    </Item>
  )
}

import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { MessageChatSquareIcon } from '@/components/icon/MessageChatSquareIcon'
import { textColor } from '@/utils/themeConfigs/customTheme'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const Message = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.448;
  color: ${textColor.dark};
`

export const ChatWelcome = () => {
  return (
    <Container>
      <Item>
        <MessageChatSquareIcon />
        <Message>今日は何をお手伝いしましょうか。</Message>
      </Item>
    </Container>
  )
}

export const ChevronUpIcon = () => {
  return (
    <svg
      width='10'
      height='6'
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.99991 4.99994L4.99991 0.999939L0.999908 4.99994'
        stroke='#841818'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

import styled from '@emotion/styled'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'

import { SettingApplicationsIcon } from '@/components/icon/SettingApplicationsIcon'
import { ChevronUpIcon } from '@/components/icon/ChevronUpIcon'
import { ChevronDownIcon } from '@/components/icon/ChevronDownIcon'
import { outlineColor, textColor } from '@/utils/themeConfigs/customTheme'

import { LlmModel, LlmModels } from '../../../types'
import { LlmModelChangeModal } from './LlmModelChangeModal'
import {
  ChangeLlmModelType,
  HandleModalSubmitArgumentType,
} from '../../../hooks/useLlmModel'

const LlmModelSettingButton = styled(Button)`
  border: 1px solid ${outlineColor.red};
  color: ${textColor.red};
  width: 180px;
  min-height: 40px;
  border-radius: 20px;
  padding: 8px 16px;
  display: flex;
  gap: 4px;
`

const ButtonText = styled(Typography)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.448;
  text-transform: none;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const LlmModelSettingMenu = styled(Menu)`
  width: 180px;
  border-radius: 6px;
  padding: 4px 16px 4px 16px;
  margin-top: -8px;
`

type Props = {
  changeLlmModel: ChangeLlmModelType
  selectedLlmModel: LlmModel | undefined
  llmModels: LlmModels
  isModalOpen: boolean
  handleCloseModal: () => void
  handleModalSubmit: HandleModalSubmitArgumentType
}

export const LlmModelSetting = ({
  changeLlmModel,
  selectedLlmModel,
  llmModels,
  isModalOpen,
  handleCloseModal,
  handleModalSubmit,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedLlmModel, setClickedLlmModel] = useState<LlmModel | null>(null)

  const isMenuOpen = Boolean(anchorEl)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleChangeModel = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.dataset.id ?? -1

    const llmModel = llmModels.find((llmModel) => llmModel.id === +id)

    // TODO: エラーハンドリング
    if (!llmModel) {
      return
    }

    setClickedLlmModel(llmModel)
    changeLlmModel({ llmModel })
    handleCloseMenu()
  }

  const handleSubmit = () => {
    clickedLlmModel && handleModalSubmit({ llmModel: clickedLlmModel })
  }

  return (
    <>
      <LlmModelSettingButton
        onClick={handleOpenMenu}
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <SettingApplicationsIcon />
        <ButtonText>{selectedLlmModel?.name}</ButtonText>
        {isMenuOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </LlmModelSettingButton>
      <LlmModelSettingMenu
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {llmModels.map((llmModel) => (
          <MenuItem key={llmModel.id} onClick={handleChangeModel} data-id={llmModel.id}>
            {llmModel.name}
          </MenuItem>
        ))}
      </LlmModelSettingMenu>
      <LlmModelChangeModal
        open={isModalOpen}
        onSubmit={handleSubmit}
        onClose={handleCloseModal}
      />
    </>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import { ChatHistoryType } from '@/components/layout/Chat/ChatHistory'
import { useDisclosure } from '@/utils/hooks/useDisclosure'
import { useFetchLlmModels } from '@/utils/hooks/useFetchLlmModels'

import { LlmModel, LlmModels } from '../types'

type UseLlmModelArgument = {
  token: string
  chatHistory: ChatHistoryType
  initializeChatHistory: () => void
}

type ChangeLlmModelArgument = {
  llmModel: LlmModel
}

export type ChangeLlmModelType = (object: ChangeLlmModelArgument) => void

type handleModalSubmitArgument = {
  llmModel: LlmModel
}

export type HandleModalSubmitArgumentType = (object: handleModalSubmitArgument) => void

type CanChangeLlmModelArgument = {
  llmModel: LlmModel
}

// LlmModelsからIDが1のものを返す
const getLlmModelWithIdOne = (llmModels: LlmModels) => {
  return llmModels.find((item) => item.id === 1)
}

export const useLlmModel = ({
  token,
  chatHistory,
  initializeChatHistory,
}: UseLlmModelArgument) => {
  const [selectedLlmModel, setSelectedLlmModel] = useState<LlmModel>()

  const { isOpen, handleOpen, handleClose } = useDisclosure()

  const { data, isFetched } = useFetchLlmModels(token)

  const router = useRouter()

  const pathName = usePathname()

  // データ取得が完了したらid:1の値を選択済みにする
  // localStorageにllmModelがある場合は、そのllmModelを選択済みにする
  // ※チャット中に別会社に切り替えた場合リロードが入りstateを維持できないためlocalStorageで対応
  useEffect(() => {
    const llmModel = localStorage.getItem('llmModel')
      ? (JSON.parse(localStorage.getItem('llmModel')!) as LlmModel)
      : undefined

    if (isFetched) {
      if (llmModel) {
        setSelectedLlmModel(llmModel)
        localStorage.removeItem('llmModel')
      } else {
        setSelectedLlmModel(getLlmModelWithIdOne(data))
      }
    }
    // HOMEチャットからModel切り替えをした時に際レンダリングされず動かない
    // pathNameでURLの変更を検知して動くようにすることで解決
  }, [isFetched, data, pathName])

  /**
   * 選択されているLLMモデルを変更する。別の会社のモデルに変更することはできない。
   * @param llmModel
   * @returns
   */
  const changeLlmModel = ({ llmModel }: ChangeLlmModelArgument) => {
    if (!canChangeLlmModel({ llmModel })) {
      handleOpen()
      return
    }

    setSelectedLlmModel(llmModel)
  }

  /**
   * 選択されているLLMモデルを確認なしで変更する
   * @param llmModel
   */
  const handleModalSubmit = ({ llmModel }: handleModalSubmitArgument) => {
    localStorage.setItem('llmModel', JSON.stringify(llmModel))
    initializeChatHistory()
    handleClose()

    router.push('/')
  }

  /**
   * LLMモデルが変更可能か確認する
   * @param llmModel
   * @returns
   */
  const canChangeLlmModel = ({ llmModel }: CanChangeLlmModelArgument) => {
    // 別の会社に変更しようとしたら処理を停止する
    return (
      chatHistory.length === 0 ||
      (selectedLlmModel && selectedLlmModel.group === llmModel.group)
    )
  }

  /**
   * IDのLLMモデルに変更する
   * @param id
   */
  const changeLlmModelFromId = useCallback(
    (id: number) => {
      const llmModel = data.find((llmModel) => llmModel.id === id)
      llmModel && setSelectedLlmModel(llmModel)
    },
    [data],
  )

  /*
   * LLMモデル選択状態の初期化
   */
  const initializeLlmModelSelecting = () => {
    setSelectedLlmModel(getLlmModelWithIdOne(data))
  }

  return {
    llmModels: data,
    selectedLlmModel,
    isOpen,
    handleClose,
    setSelectedLlmModel,
    changeLlmModel,
    handleModalSubmit,
    changeLlmModelFromId,
    initializeLlmModelSelecting,
  }
}

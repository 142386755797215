import { useRouter } from 'next/router' // shallowがnext/navigationにない
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { MutationKeys } from '@/utils/apis/api-keys'

type ChatImageResponse = {
  chat_room_id: string
  image: string
  text: string
  type: 'answer' | 'error'
}

type MutationFnArgument = {
  token: string
  question: string
  model_id: number
  chatRoomId?: string
}

export const useCreateImage = () => {
  const { apiClient } = useApiClient()

  const queryClient = useQueryClient()
  const router = useRouter()

  return useMutation({
    mutationFn: ({ token, question, chatRoomId, model_id }: MutationFnArgument) => {
      const chatRoomQuery = chatRoomId ? `&chat_room_id=${chatRoomId}` : ''
      const url =
        API.chatImage + `?question=${question}&llm_model_id=${model_id}${chatRoomQuery}`

      return apiClient(token)
        .post<ChatImageResponse>(url)
        .then((res) => res.data)
    },
    onSuccess: async ({ chat_room_id }) => {
      // URLの変更
      const { chatRoomId } = router.query
      if (!chatRoomId) {
        await router.push('', `/${chat_room_id}`, { shallow: true })
      }

      // 履歴一覧の反映
      await queryClient.invalidateQueries({ queryKey: MutationKeys.history })
    },
    onSettled: async () => {},
  })
}

export const MicrophoneIcon = () => {
  return (
    <svg
      width='16'
      height='22'
      viewBox='0 0 16 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 9V11C15 14.866 11.866 18 8 18M1 9V11C1 14.866 4.13401 18 8 18M8 18V21M4 21H12M8 14C6.34315 14 5 12.6569 5 11V4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4V11C11 12.6569 9.65685 14 8 14Z'
        stroke='#841818'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

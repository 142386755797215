export const SendIcon = () => {
  return (
    <svg
      width='22'
      height='20'
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.57437 2.72931C1.3929 2.18255 1.30216 1.90917 1.36821 1.74078C1.42556 1.59454 1.54832 1.48375 1.69966 1.44164C1.87392 1.39315 2.13659 1.51135 2.66193 1.74776L19.3792 9.2705C19.8936 9.50195 20.1507 9.61767 20.2302 9.77843C20.2993 9.9181 20.2993 10.082 20.2302 10.2216C20.1507 10.3824 19.8936 10.4981 19.3792 10.7296L2.66776 18.2497C2.14083 18.4869 1.87736 18.6054 1.70292 18.5567C1.55144 18.5145 1.42868 18.4034 1.37152 18.2569C1.3057 18.0881 1.39742 17.8142 1.58085 17.2662L3.91577 10.2915C3.94718 10.1977 3.96289 10.1508 3.96916 10.1028C3.97473 10.0602 3.97479 10.0171 3.96933 9.97444C3.96317 9.92642 3.94759 9.87947 3.91642 9.78556L1.57437 2.72931Z'
        fill='#841818'
      />
      <path
        d='M9.50043 10H4.00043M3.91577 10.2915L1.58085 17.2662C1.39742 17.8142 1.3057 18.0881 1.37152 18.2569C1.42868 18.4034 1.55144 18.5145 1.70292 18.5567C1.87736 18.6054 2.14083 18.4869 2.66776 18.2497L19.3792 10.7296C19.8936 10.4981 20.1507 10.3824 20.2302 10.2216C20.2993 10.082 20.2993 9.9181 20.2302 9.77843C20.1507 9.61767 19.8936 9.50195 19.3792 9.2705L2.66193 1.74776C2.13659 1.51135 1.87392 1.39315 1.69966 1.44164C1.54832 1.48375 1.42556 1.59454 1.36821 1.74078C1.30216 1.90917 1.3929 2.18255 1.57437 2.72931L3.91642 9.78556C3.94759 9.87947 3.96317 9.92642 3.96933 9.97444C3.97479 10.0171 3.97473 10.0602 3.96916 10.1028C3.96289 10.1508 3.94718 10.1977 3.91577 10.2915Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

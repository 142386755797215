import { useQuery } from '@tanstack/react-query'

import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'

export type Histories = [
  {
    id: string
    chat_title: string
  },
]

export const useFetchHistories = (token: string) => {
  const { apiClient } = useApiClient()

  const client = apiClient(token)
  return useQuery({
    queryKey: MutationKeys.history,
    queryFn: () => client.get<Histories>(API.history).then((res) => res.data),
  })
}

import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { Role } from '@/components/layout/Chat/ChatBubble'

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError
  }
}

type UseFetchChatRoomArgument = {
  token: string
  chatRoomId: string | undefined
}

export type ImageChatContentType = {
  role: 'assistant_with_image' | 'user_with_image'
  content: {
    image: string[]
    text: string
  }
}

export type TextChatContentType = {
  role: Role
  content: string
}

export type ChatContentType = ImageChatContentType | TextChatContentType

export type ChatLogType = [
  {
    function_type: number
    id: string
    original_prompt_id: string
    llm_model_id: number
  },
  ChatContentType[],
]

export const useFetchChatRoom = ({ token, chatRoomId }: UseFetchChatRoomArgument) => {
  const { apiClient } = useApiClient()
  const client = apiClient(token)

  return useQuery({
    queryKey: [...MutationKeys.fetchChatHistory, chatRoomId],
    queryFn: () =>
      client
        .get<ChatLogType>(`${API.history}/${chatRoomId ?? ''}`)
        .then((res) => res.data),
    enabled: !!chatRoomId,
    retry: false,
  })
}

export const MessageChatSquareIcon = () => {
  return (
    <svg
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6667 23.5L9.54125 28.6895C8.82632 29.4134 8.46885 29.7753 8.1616 29.8008C7.89503 29.823 7.63404 29.7158 7.45994 29.5127C7.25927 29.2787 7.25927 28.77 7.25927 27.7526V25.1527C7.25927 24.2399 6.5118 23.5795 5.60868 23.4472V23.4472C3.42292 23.127 1.7063 21.4104 1.38614 19.2247C1.33334 18.8642 1.33334 18.4342 1.33334 17.5741V9.83333C1.33334 7.03307 1.33334 5.63293 1.87831 4.56338C2.35768 3.62257 3.12258 2.85766 4.06339 2.3783C5.13295 1.83333 6.53308 1.83333 9.33334 1.83333H21.6667C24.4669 1.83333 25.8671 1.83333 26.9366 2.3783C27.8774 2.85766 28.6423 3.62257 29.1217 4.56338C29.6667 5.63293 29.6667 7.03307 29.6667 9.83333V16.8333M29.6667 35.1667L26.0393 32.6448C25.5294 32.2903 25.2745 32.113 24.997 31.9874C24.7507 31.8758 24.4918 31.7947 24.226 31.7457C23.9264 31.6905 23.6159 31.6905 22.9949 31.6905H20C18.1332 31.6905 17.1997 31.6905 16.4867 31.3272C15.8595 31.0076 15.3496 30.4976 15.03 29.8704C14.6667 29.1574 14.6667 28.224 14.6667 26.3571V22.1667C14.6667 20.2998 14.6667 19.3664 15.03 18.6534C15.3496 18.0262 15.8595 17.5162 16.4867 17.1966C17.1997 16.8333 18.1332 16.8333 20 16.8333H29.3333C31.2002 16.8333 32.1336 16.8333 32.8466 17.1966C33.4739 17.5162 33.9838 18.0262 34.3034 18.6534C34.6667 19.3664 34.6667 20.2998 34.6667 22.1667V26.6905C34.6667 28.2436 34.6667 29.0202 34.4129 29.6327C34.0746 30.4495 33.4257 31.0984 32.609 31.4367C31.9964 31.6905 31.2198 31.6905 29.6667 31.6905V35.1667Z'
        stroke='#841818'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { NextRouter, useRouter } from 'next/router'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { Layout } from '@/components/layout/Layout/Layout'
import { ChatHistory } from '@/components/layout/Chat/ChatHistory'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useAuthContext } from '@/context/AuthContext'
import { useDisclosure } from '@/utils/hooks/useDisclosure'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { useInfoContext } from '@/context/InfoConrtext'
import { dateConverter } from '@/utils/helpers/convertDateFormat'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useSnackbarContext } from '@/context/SnackbarContext'

import { History } from '../ui/History/History'
import { ChatWelcome } from '../ui/chat/ChatWelcome'
import { ChatForm } from '../ui/chat/ChatForm'
import { useChatLogic } from '../../hooks/useChatLogic'
import { useLlmModel } from '../../hooks/useLlmModel'
import { useFetchChatRoom } from '../../hooks/useFetchChatRoom'
import { NewChatButton } from '../ui/chat/NewChatButton'
import { useSpeechRecognition } from '../../hooks/useSpeechRecognition'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 32px 8px;
  display: flex;
  flex-direction: column;

  ${mediaQuery('tab')} {
    padding: 0;
    height: 100%;
    min-height: 100%;
    position: relative;
  }
`

const Main = styled(Box)`
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  min-height: 0;
  overflow: scroll;
  ${mediaQuery('tab')} {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 125px;
  }
`

const DialogContent = styled(Box)`
  font-size: 16px;
  font-weight: 700;
`

const ItemContainer = styled('div')`
  max-width: 358px;
  display: flex;
  gap: 18px;
  padding: 16px 0 0;
  margin: 0 auto;
`

type HandleSendChatArgumentType = {
  message?: string
  callback?: () => void
}

export type HandleSendChatType = (param?: HandleSendChatArgumentType) => void

/**
 * URLからChatRoomIdを取得する関数
 */
const getChatRoomId = ({
  router,
  pathname,
}: {
  router: NextRouter
  pathname: string
}) => {
  const { chatRoomId } = router.query
  if (typeof chatRoomId === 'string') {
    return chatRoomId
  }

  if (!pathname) return undefined

  // pathnameで渡された値から先頭と末尾の「/」を抜いた値をChatRoomIdとする
  return pathname.replace(/^\/|\/$/g, '')
}

export const Chat = () => {
  const isMobile = useIsMobileOrTablet()
  const { isOpen: isMenuOpen, handleOpen, handleClose: handleMenuClose } = useDisclosure()
  const { user } = useAuthContext()
  const token = user?.token ?? ''
  const { modal, infoSummary, handleModalClose, pageTransition } = useInfoContext()

  const {
    chatInputValue,
    handleChangeChatInput,
    isValid,
    chatHistory,
    sendChat,
    isAnswering,
    syncChatHistory,
    initializeChatHistory,
    initializeChatInput,
  } = useChatLogic()

  const {
    selectedLlmModel,
    llmModels,
    changeLlmModel,
    handleModalSubmit,
    isOpen,
    handleClose,
    changeLlmModelFromId,
    initializeLlmModelSelecting,
  } = useLlmModel({ token, chatHistory, initializeChatHistory })

  // クエリ受け取ってChatHistoryにセットする
  const router = useRouter()
  const pathname = usePathname()
  const chatRoomId = getChatRoomId({ router, pathname })

  const { data, error } = useFetchChatRoom({ token, chatRoomId })

  const { showSnackbar } = useSnackbarContext()

  // TODO: 削除済みの履歴を開こうとした際の処理
  useEffect(() => {
    if (data) {
      syncChatHistory(data)

      changeLlmModelFromId(data[0].llm_model_id)
    }
  }, [data, changeLlmModelFromId, syncChatHistory])

  useEffect(() => {
    if (error?.response?.status === 404) {
      void (async () => {
        await router.push('/')
      })()
    }
  }, [error, router])

  // 音声入力などstate更新せずに送信したい時があるので送るメッセージを外から入れれるようにした
  const handleSendChat = ({ message, callback }: HandleSendChatArgumentType = {}) => {
    ;(async () => {
      // ここがundefinedになる場合は別の箇所でトーストが表示されるのでここでは処理を行わない
      if (!token || !selectedLlmModel) return

      await sendChat({
        token,
        model_id: selectedLlmModel.id,
        model_group: selectedLlmModel.group,
        chat_room_id: chatRoomId,
        message,
      })

      callback?.()
    })().catch(() => {
      showSnackbar?.({
        text: 'エラーが発生しました。時間をおいて再度お試しください。',
        severity: 'error',
      })
    })
  }

  const { isRecording, startRecording, stopRecording } = useSpeechRecognition({
    handleSendChat,
    handleChangeChatInput,
    chatInputValue,
  })

  const initializeChat = () => {
    initializeChatHistory()

    initializeLlmModelSelecting()

    initializeChatInput()

    stopRecording()

    void (async () => {
      await router.push('/')
    })()
  }

  return (
    <>
      <Layout
        sideMenuContent={<History onGoToChatRoom={stopRecording} />}
        headerTitle='CHAT'
        mobileButton={<NewChatButton onClick={initializeChat} />}
        mobileMenu={{
          content: <History onGoToChatRoom={handleMenuClose} />,
          isMenuOpen,
          openMenu: handleOpen,
          closeMenu: handleMenuClose,
        }}
      >
        <Container>
          <Main>
            {chatHistory.length === 0 ? (
              <ChatWelcome />
            ) : (
              <ChatHistory chatHistory={chatHistory} isAnswering={isAnswering} />
            )}
          </Main>
          <ChatForm
            handleSendChat={handleSendChat}
            chatInputValue={chatInputValue}
            handleChangeChatInput={handleChangeChatInput}
            isValid={isValid}
            changeLlmModel={changeLlmModel}
            selectedLlmModel={selectedLlmModel}
            llmModels={llmModels || []}
            isAnswering={isAnswering}
            isOpen={isOpen}
            isMobile={isMobile}
            handleCloseModal={handleClose}
            handleModalSubmit={handleModalSubmit}
            isRecording={isRecording}
            startRecording={startRecording}
            stopRecording={stopRecording}
          />
        </Container>
      </Layout>
      {modal && (
        <BasicModal
          wrapperWidth='large'
          innerWidth='large'
          open={modal.isOpen}
          onClose={handleModalClose}
          title='新しいお知らせがあります'
        >
          <>
            <DialogContent>
              {dateConverter(infoSummary?.created_at ?? '', 'long', '.')}{' '}
              {infoSummary?.title}
            </DialogContent>
            <ItemContainer>
              <BasicButton
                onClick={handleModalClose}
                label='閉じる'
                variant='outlined'
                width='140px'
                height='47px'
              />
              <BasicButton
                label='一覧へ'
                width='140px'
                height='47px'
                onClick={pageTransition}
              />
            </ItemContainer>
          </>
        </BasicModal>
      )}
    </>
  )
}

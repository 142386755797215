import { useCallback } from 'react'

import { API } from '@/utils/apis/endpoints'

const groupUrlMap: { [key: number]: string } = {
  0: API.chatImage,
  1: API.chatGpt,
  2: API.chatGemini,
  3: API.chatClaude,
  90: API.chatImage,
}

export const useChatEndpoint = () => {
  const getEndpoint = (group: number): string | undefined => {
    return groupUrlMap[group]
  }

  const canConnectToApiEndpoint = useCallback((group: number) => {
    return group in groupUrlMap
  }, [])

  return {
    getEndpoint,
    canConnectToApiEndpoint,
  }
}
